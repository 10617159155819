import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { hideMintNFTDialog, setPricePerNFT, setSaleInProgress, setSaleType } from '../../actions/home';
import { connect } from 'react-redux';
import { config } from '../../config';

const Counter = (props) => {
    const [time, setTime] = useState();
    const [timerObj] = useState({ timer: false });
    const {
        startTime,
        endTime,
        setSaleType,
        saleInProgress,
    } = props;
    const denom = props.project && props.project.price && props.project.price.denom;
    const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
        props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

    const handleTimer = () => {
        const startTimeObj = new Date(startTime).getTime();
        const endTimeObj = new Date(endTime).getTime();
        let distance = 0;
        let distanceType = 'end';

        if (moment().diff(startTime) < -1000) {
            distanceType = 'start';
            const now = new Date().getTime();
            distance = startTimeObj - now;
            if (saleInProgress) {
                props.setSaleInProgress(false);
            }
        } else if (moment().diff(endTime) < -1000) {
            distanceType = 'end';
            const now = new Date().getTime();
            distance = endTimeObj - now;
            if (!saleInProgress) {
                props.setSaleInProgress(true);
            }
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const obj = {
            days,
            hours,
            minutes,
            seconds,
        };

        setTime(obj);

        if (distance < 1000 && props.saleType === 'pre' && distanceType === 'end') {
            const data = props.project;
            const decimals = (ibcToken && ibcToken.network && ibcToken.network.decimals) || config.COIN_DECIMALS;
            let value = data && data.price;
            value = value && value.amount && value.amount / (10 ** decimals);

            props.setPricePerNFT(value);
            props.setSaleType('public');
            props.hideMintNFTDialog();
        }
        if (distanceType === 'start' || distance > 0) {
            timerObj.timer = setTimeout(handleTimer, 1000);
        } else {
            if (saleInProgress) {
                props.setSaleInProgress(false);
                props.hideMintNFTDialog();
            }
        }
    };

    useEffect(() => {
        if (timerObj.timer) {
            clearTimeout(timerObj.timer);
        }
        handleTimer();
        /* eslint-disable */
    }, [startTime, endTime, setSaleType, saleInProgress]);

    return (
        <>
            {moment().diff(props.startTime) < -1000
                ? <>
                    <span className="divider"/>
                    <div className="counter">
                        <p>{props.saleType === 'pre' ? 'Pre ' : ''}Sale starts in&nbsp;</p>
                        <span>
                        {time && time.days ? time.days + 'd ' : ''}
                            {time && time.hours ? time.hours + 'h ' : ''}
                            {time && time.minutes ? time.minutes + 'm ' : ''}
                            {time && time.seconds ? time.seconds + 's' : ''}
                        </span>
                    </div>
                </>
                : moment().diff(props.endTime) < -1000
                    ? props.saleType === 'pre' && <>
                    <span className="divider"/>
                    <div className="counter">
                        <p>{props.saleType === 'pre' ? 'Pre ' : ''}Sale ends in&nbsp;</p>
                        <span>
                            {time && time.days ? time.days + 'd ' : ''}
                            {time && time.hours ? time.hours + 'h ' : ''}
                            {time && time.minutes ? time.minutes + 'm ' : ''}
                            {time && time.seconds ? time.seconds + 's' : ''}
                        </span>
                    </div>
                </>
                    : <>
                        <span className="divider"/>
                        <div className="counter">
                            <p>Sale completed</p>
                        </div>
                    </>}
        </>
    );
};

Counter.propTypes = {
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    hideMintNFTDialog: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    saleInProgress: PropTypes.bool.isRequired,
    saleType: PropTypes.string.isRequired,
    setPricePerNFT: PropTypes.func.isRequired,
    setSaleInProgress: PropTypes.func.isRequired,
    setSaleType: PropTypes.func.isRequired,
    endTime: PropTypes.string,
    startTime: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        saleType: state.home.saleType,
        saleInProgress: state.home.saleInProgress,
    };
};

const actionToProps = {
    hideMintNFTDialog,
    setPricePerNFT,
    setSaleInProgress,
    setSaleType,
};

export default connect(stateToProps, actionToProps)(Counter);
