import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchTransactions, fetchTransfers, hideSidePanel } from '../../../actions/home';
import ChainIDCard from '../ChainIDCard';
import variables from '../../../utils/variables';
import successIcon from '../../../assets/snackbar/success.svg';
import errorIcon from '../../../assets/snackbar/error.svg';
import CopyButton from '../../../components/CopyButton';
import copyIcon from '../../../assets/copy.svg';
import shareIcon from '../../../assets/snackbar/share.svg';
import ClassNames from 'classnames';
import { config, DEFAULT_LAZY_FETCH_HEIGHT, DEFAULT_LIMIT, EXPLORER_URL } from '../../../config';
import waitingIcon from '../../../assets/snackbar/waiting.svg';
import NoData from '../../../components/NoData';
import Icon from '../../../components/Icon';

const SidePanel = (props) => {
    const handleScroll = (e) => {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((props.transactions.length < props.transactionsTotal) &&
            ((scrollHeight - scroll) <= (height + DEFAULT_LAZY_FETCH_HEIGHT)) &&
            !(props.transactionsInProgress)) {
            props.fetchTransactions(props.transactionsSkip + DEFAULT_LIMIT, props.transactionsLimit);
        }
    };

    const handleScrollTransfers = (e) => {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((props.transfers.length < props.transfersTotal) &&
            ((scrollHeight - scroll) <= (height + DEFAULT_LAZY_FETCH_HEIGHT)) &&
            !(props.transfersInProgress)) {
            props.fetchTransfers(props.transfersSkip + DEFAULT_LIMIT, props.transfersLimit);
        }
    };

    return (
        <Drawer
            anchor="right"
            className="side_panel"
            open={props.open}
            onClose={props.onClose}>
            <div className="side_panel_content section3 scroll_bar">
                <div className="back_button">
                    <IconButton
                        onClick={props.onClose}>
                        <Icon className="close" icon="close"/>
                    </IconButton>
                </div>
                <ChainIDCard hideButton/>
                {props.mintQueue && props.mintQueue.length
                    ? <div className="transaction_queue">
                        <h4>{variables[props.lang]['transactions_in_queue']}</h4>
                        {props.mintQueue.map((value, index) => {
                            return (
                                <div key={index} className="queue_card">
                                    <div className="snackbar_class success_snackbar" id="message-id">
                                        <img alt="icon" src={waitingIcon}/>
                                        <span>{variables[props.lang]['transaction_in_queue']}</span>
                                    </div>
                                    <div className="count_info">
                                        <p><span>{variables[props.lang]['queue_number'] + ' : '}</span>{value.position}
                                        </p>
                                        {value && value.mint_request && value.mint_request.quantity &&
                                        <p className="nft_count"><span>Mint NFTs: </span>{value.mint_request.quantity}
                                        </p>}
                                    </div>
                                </div>
                            );
                        })}
                    </div> : null}
                <div className="transaction_history">
                    <h4>{variables[props.lang]['transaction_history']}</h4>
                    <div className="scroll_bar" onScroll={handleScroll}>
                        {props.transactions && props.transactions.length
                            ? props.transactions.map((value, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={ClassNames(
                                            'transaction_card',
                                            (value && value.status === 'COMPLETED') ? 'transaction_card_success' : '',
                                            (value && value.status === 'VALID') ? 'transaction_card_in_progress' : '',
                                            (value && (value.status === 'FAILED' || value.status === 'EXPIRED'))
                                                ? 'transaction_card_failed' : '')}>
                                        {value && value.status === 'COMPLETED'
                                            ? <div className="snackbar_class" id="message-id">
                                                <img alt="icon" src={successIcon}/>
                                                <span>{variables[props.lang]['transaction_success']}</span>
                                                {value && value.mint_tx && <div className="action_buttons">
                                                    <CopyButton
                                                        data={EXPLORER_URL + '/txs/' + value.mint_tx}
                                                        icon={copyIcon}/>
                                                    <img
                                                        alt="share"
                                                        src={shareIcon}
                                                        onClick={() => window.open(EXPLORER_URL + '/txs/' + value.mint_tx)}/>
                                                </div>}
                                            </div>
                                            : value && value.status === 'FAILED'
                                                ? <div className="snackbar_class" id="message-id">
                                                    <img alt="icon" src={errorIcon}/>
                                                    <span>{variables[props.lang]['transaction_failed']}</span>
                                                    {value && value.mint_tx && <div className="action_buttons">
                                                        <CopyButton
                                                            data={EXPLORER_URL + '/txs/' + value.mint_tx}
                                                            icon={copyIcon}/>
                                                        <img
                                                            alt="share"
                                                            src={shareIcon}
                                                            onClick={() => window.open(EXPLORER_URL + '/txs/' + value.mint_tx)}/>
                                                    </div>}
                                                </div>
                                                : value && value.status === 'EXPIRED'
                                                    ? <div className="snackbar_class" id="message-id">
                                                        <img alt="icon" src={errorIcon}/>
                                                        <span>{variables[props.lang]['transaction_expired']}</span>
                                                    </div>
                                                    : value && value.status === 'VALID'
                                                        ? <div className="snackbar_class" id="message-id">
                                                            <img alt="icon" src={waitingIcon}/>
                                                            <span>{variables[props.lang]['transaction_in_process']}</span>
                                                        </div>
                                                        : value && value.status === 'INVALID'
                                                            ? <div className="snackbar_class" id="message-id">
                                                                <span className="circle_image"/>
                                                                <span>{variables[props.lang]['invalid_transfer_amount']}</span>
                                                            </div>
                                                            : value && value.status === 'CREATED'
                                                                ? <div className="snackbar_class" id="message-id">
                                                                    <span className="circle_image"/>
                                                                    <span>{variables[props.lang]['mint_request_created']}</span>
                                                                </div> : null}
                                        {value && <div className="tx_hash">
                                            <p className="nft_count">NFTs: {value.quantity}</p>
                                            {value.mint_tx &&
                                            <>
                                                <span>TxnHash</span>
                                                <p>{value.mint_tx}</p>
                                            </>}
                                        </div>}
                                    </div>
                                );
                            }) : <NoData/>}
                    </div>
                </div>
                {props.transfers && props.transfers.length
                    ? <div className="transaction_history">
                        <h4>{variables[props.lang]['transfers_history']}</h4>
                        <div className="scroll_bar" onScroll={handleScrollTransfers}>
                            {props.transfers.map((value, index) => {
                                const denom = value && value.amount && value.amount.denom;
                                const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
                                    props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                                const decimals = (ibcToken && ibcToken.network && ibcToken.network.decimals) || config.COIN_DECIMALS;
                                const displayDenom = (ibcToken && ibcToken.network && ibcToken.network.display_denom) || config.COIN_DENOM;

                                let balance = value && value.amount;
                                balance = balance && balance.amount && balance.amount / (10 ** decimals);
                                return (
                                    <div
                                        key={index}
                                        className={ClassNames(
                                            'transaction_card',
                                            (value && value.refund_status === 'REFUNDED') ? 'transaction_card_success' : '')}>
                                        {value && value.refund_status === 'REFUNDED'
                                            ? <div className="snackbar_class" id="message-id">
                                                <img alt="icon" src={successIcon}/>
                                                <span>{variables[props.lang]['transfer_success']}</span>
                                                {value && value.hash && <div className="action_buttons">
                                                    <CopyButton
                                                        data={EXPLORER_URL + '/txs/' + value.hash}
                                                        icon={copyIcon}/>
                                                    <img
                                                        alt="share"
                                                        src={shareIcon}
                                                        onClick={() => window.open(EXPLORER_URL + '/txs/' + value.hash)}/>
                                                </div>}
                                            </div>
                                            : value && value.refund_status === 'REFUND'
                                                ? <div className="snackbar_class" id="message-id">
                                                    <span className="circle_image"/>
                                                    <span>{variables[props.lang].refund}</span>
                                                    {value && value.hash && <div className="action_buttons">
                                                        <CopyButton
                                                            data={EXPLORER_URL + '/txs/' + value.hash}
                                                            icon={copyIcon}/>
                                                        <img
                                                            alt="share"
                                                            src={shareIcon}
                                                            onClick={() => window.open(EXPLORER_URL + '/txs/' + value.hash)}/>
                                                    </div>}
                                                </div> : null}
                                        {value && <div className="tx_hash">
                                            <p className="nft_count">Amount: {balance + ' ' + displayDenom}</p>
                                            {value.hash &&
                                            <>
                                                <span>TxnHash</span>
                                                <p>{value.hash}</p>
                                            </>}
                                        </div>}
                                    </div>
                                );
                            })}
                        </div>
                    </div> : null}
            </div>
        </Drawer>
    );
};

SidePanel.propTypes = {
    address: PropTypes.string.isRequired,
    fetchTransactions: PropTypes.func.isRequired,
    fetchTransfers: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    mintQueue: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    transactions: PropTypes.array.isRequired,
    transactionsInProgress: PropTypes.bool.isRequired,
    transfers: PropTypes.array.isRequired,
    transfersInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    transactionsLimit: PropTypes.number,
    transactionsSkip: PropTypes.number,
    transactionsTotal: PropTypes.number,
    transfersLimit: PropTypes.number,
    transfersSkip: PropTypes.number,
    transfersTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        lang: state.language,
        mintQueue: state.mint.mintQueue.value,
        open: state.home.sidePanel,
        project: state.mint.project.value,
        transactions: state.home.transactions.value,
        transactionsInProgress: state.home.transactions.inProgress,
        transactionsLimit: state.home.transactions.limit,
        transactionsSkip: state.home.transactions.skip,
        transactionsTotal: state.home.transactions.total,
        transfers: state.home.transfers.value,
        transfersInProgress: state.home.transfers.inProgress,
        transfersLimit: state.home.transfers.limit,
        transfersSkip: state.home.transfers.skip,
        transfersTotal: state.home.transfers.total,
    };
};

const actionToProps = {
    fetchTransactions,
    fetchTransfers,
    onClose: hideSidePanel,
};

export default connect(stateToProps, actionToProps)(SidePanel);
