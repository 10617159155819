// import ClaimFeeGrantCard from './ClaimFeeGrantCard';
import React from 'react';
import MintNFTSCard from './MintNFTSCard';
// import DepositCard from './DepositCard';
import ChainIDCard from './ChainIDCard';
import MintNFTDialog from './MintNFTDialog';
import DepositDialog from './DepositDialog';
import WithDrawDialog from './WithDrawDialog';

const Section3 = () => {
    return (
        <div className="section3">
            {/* <div> */}
            {/*     <ChainIDCard/> */}
            {/*     <ClaimFeeGrantCard/> */}
            {/*     <DepositCard/> */}
            {/* </div> */}
            <div>
                <ChainIDCard/>
                <MintNFTSCard/>
            </div>
            <MintNFTDialog/>
            <DepositDialog/>
            <WithDrawDialog/>
        </div>
    );
};

export default Section3;
